import axios from 'axios';
import { setError, setShippingAddress, clearOrder,setSelectedDeliveryTime } from '../slices/order';

export const setAddress = (data) => (dispatch) => {
    dispatch(setShippingAddress(data));
};

export const setDeliveryTime = (value) => (dispatch) => {
    dispatch(setSelectedDeliveryTime(value));
    console.log("Selected Delivery Time:", value);
};


export const setPayment = () => async (dispatch, getState) => {
    const {
        cart: { cartItems, subtotal, shipping, packing, packagingPrice }, 
        order: { shippingAddress,selectedDeliveryTime },
        user: { userInfo },
    } = getState();
   
    const newOrder = { 
        subtotal, 
        shipping, 
        shippingAddress, 
        cartItems, 
        userInfo, 
        packing, 
        packagingPrice,
        paymentOption: 'withoutExpress',
        selectedDeliveryTime
    }; 

    console.log('New Order:', newOrder); 

    try {
        const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' } };

        const { data } = await axios.post('api/checkout', newOrder, config);
        window.location.assign(data.url);
       
    } catch (error) {
        setError(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                ? error.message
                : 'An expected error has occured. Please try again later.'
        );
    }
};

export const resetOrder = () => async (dispatch) => {
    dispatch(clearOrder());
};
