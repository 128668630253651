import React, { useState } from 'react';
import {
    Button,
    Image,
    Text,
    Checkbox,
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogOverlay,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';


const Szabadsag = ({ isOpen, onClose }) => {



    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };



    return (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                        Kedves Vásárlóink!
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <Text textAlign="center">
                            Ezúton szeretnénk tájékoztatni Önöket, hogy üzletünk <b>2024. december 4.</b>-én zárva tart.<br /><br />
                            Köszönjük megértésüket és türelmüket!
                        </Text>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={onClose}>Bezár</Button>
                        <Button colorScheme="red" onClick={onClose} ml={3}>
                            Értem
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>

            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default Szabadsag;
